<template>
  <div class="categorie-setting">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div
        v-if="
          (!getCategoriesProjects || !getCategoriesProjects.length) &&
            (!getCategoriesContacts || !getCategoriesContacts.length)
        "
        class="div-aucun-list"
      >
        <div class="titre">
          {{ $i18n.locale === 'fr' ? 'Aucun catégorie' : 'No category' }}
        </div>
      </div>
      <div class="card-scene" ref="cardSceneCategory" v-else>
        <div v-if="selectedMenu === 'projets'">
          <Container
            :drop-placeholder="dropPlaceholderOptions"
            :lock-axis="'y'"
            @drop="onColumnDropProjects"
            drag-handle-selector=".column-drag-container"
          >
            <Draggable
              v-for="(categorie, index) in getCategoriesProjects"
              :key="index"
              :value="index.id"
            >
              <div class="">
                <div class="">
                  <div class="card-column-header">
                    <div class="input-title-sous-type">
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            :label="$t('designation')"
                            dense
                            placeholder="Modifier le nom catégorie"
                            @change="updateNameCategorie($event, categorie)"
                            title="Modifier le nom catégorie"
                            :value="categorie.name"
                            :persistent-placeholder="true"
                            outlined
                            color="#5C2DD3"
                            item-color="#5C2DD3"
                          >
                            <template #prepend>
                              <v-icon
                                class="cursor-move mr-2 ml-2 bold-700 color-crm font-sz-30 mb-1"
                                :class="{
                                  'column-drag-container':
                                    getCategoriesProjects &&
                                    getCategoriesProjects.length > 1
                                }"
                                >mdi-drag-horizontal-variant</v-icon
                              >
                            </template>
                          </v-text-field></v-col
                        >
                        <v-col cols="8">
                          <v-autocomplete
                            :clearable="true"
                            color="#5C2DD3"
                            multiple
                            :placeholder="
                              $t('searchMsg', { msg: $t('typeProjet') })
                            "
                            item-color="#5C2DD3"
                            @input="updateTypeCategorie($event, categorie)"
                            v-model="categorie.dtypes"
                            :loading="getProjectsTypesLoading"
                            :items="getProjectsTypes"
                            :persistent-placeholder="true"
                            chips
                            return-object
                            :deletable-chips="true"
                            :small-chips="true"
                            :label="$t('typeProjet')"
                            item-text="name"
                            dense
                            item-value="name"
                            :no-data-text="`Aucune type de projet trouvée`"
                            outlined
                            :menu-props="{
                              bottom: true,
                              offsetY: true
                            }"
                          >
                            <template #append-outer>
                              <v-icon
                                class="font-sz-30 color-crm"
                                :title="
                                  $i18n.locale === 'fr'
                                    ? 'Configuration des sous-catégories'
                                    : 'Sub-category configuration'
                                "
                                @click.prevent.stop="
                                  sendConfigCategoryProjet(categorie)
                                "
                                >mdi-cog-outline</v-icon
                              >
                            </template>
                          </v-autocomplete></v-col
                        >
                        <v-col cols="1">
                          <v-btn
                            class="font-sz-30 bold-700 float-right color-crm"
                            @click="
                              handleDeleteClick(categorie, (type = 'projects'))
                            "
                            :title="
                              $i18n.locale === 'fr'
                                ? 'Supprimer une catégorie'
                                : 'Delete category'
                            "
                            fab
                            small
                            icon
                          >
                            <v-icon class="font-sz-30 mt-1"
                              >mdi-close-circle-outline</v-icon
                            ></v-btn
                          ></v-col
                        >
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
        </div>
        <div v-if="selectedMenu === 'contacts'">
          <Container
            :drop-placeholder="dropPlaceholderOptions"
            :lock-axis="'y'"
            @drop="onColumnDropContact"
            drag-handle-selector=".column-drag-container "
          >
            <Draggable
              v-for="(categorie, index) in getCategoriesContacts"
              :key="index"
              :value="index.id"
            >
              <div class="mt-1">
                <div class="card-column-header">
                  <div class="input-title-sous-type">
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          :label="$t('designation')"
                          dense
                          :placeholder="
                            $i18n.locale === 'fr'
                              ? 'Modifier le nom catégorie'
                              : 'Modify category name'
                          "
                          @change="updateNameCategorie($event, categorie)"
                          :title="
                            $i18n.locale === 'fr'
                              ? 'Modifier le nom catégorie'
                              : 'Modify category name'
                          "
                          :value="categorie.name"
                          :persistent-placeholder="true"
                          outlined
                          color="#5C2DD3"
                          item-color="#5C2DD3"
                        >
                          <template #prepend>
                            <v-icon
                              class="cursor-move mr-2 ml-2 bold-700 color-crm font-sz-30 mb-1"
                              :class="{
                                'column-drag-container':
                                  getCategoriesContacts &&
                                  getCategoriesContacts.length > 1
                              }"
                              >mdi-drag-horizontal-variant</v-icon
                            >
                          </template>
                        </v-text-field></v-col
                      >
                      <v-col cols="8">
                        <v-autocomplete
                          :clearable="true"
                          color="#5C2DD3"
                          multiple
                          :placeholder="
                            $t('searchMsg', { msg: $t('typeProjet') })
                          "
                          item-color="#5C2DD3"
                          @input="updateTypeCategorie($event, categorie)"
                          v-model="categorie.dtypes"
                          :loading="getProjectsTypesLoading"
                          :items="getProjectsTypes"
                          :persistent-placeholder="true"
                          chips
                          return-object
                          :deletable-chips="true"
                          :small-chips="true"
                          :label="$t('typeProjet')"
                          item-text="name"
                          dense
                          item-value="name"
                          :no-data-text="`Aucune type de projet trouvée`"
                          outlined
                          :menu-props="{
                            bottom: true,
                            offsetY: true
                          }"
                        >
                          <template #append-outer>
                            <v-icon
                              class="font-sz-30 color-crm"
                              :title="
                                $i18n.locale === 'fr'
                                  ? 'Configuration des sous-catégories'
                                  : 'Sub-category configuration'
                              "
                              @click.prevent.stop="
                                sendConfigCategoryContact(categorie)
                              "
                              >mdi-cog-outline</v-icon
                            >
                          </template>
                        </v-autocomplete></v-col
                      >
                      <v-col cols="1">
                        <v-btn
                          class="font-sz-30 bold-700 float-right color-crm"
                          @click="
                            handleDeleteClick(categorie, (type = 'contacts'))
                          "
                          :title="
                            $i18n.locale === 'fr'
                              ? 'Supprimer une catégorie'
                              : 'Delete category'
                          "
                          fab
                          small
                          icon
                        >
                          <v-icon class="font-sz-30 mt-1"
                            >mdi-close-circle-outline</v-icon
                          >
                        </v-btn></v-col
                      >
                    </v-row>
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
        </div>
      </div>
    </div>
    <!-- Delete Categorie Modal -->
    <v-dialog
      v-model="ModalCategorieDelete"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer une catégorie'
                : 'Delete category'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalCategorieDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="parg">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'la catégorie :' : 'the category :'
              })
            }}
            <strong>
              {{ categorieToDelete ? categorieToDelete.name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalDelete"
            :loading="getCategorieLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalCategorieDelete')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Categorie Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  data() {
    return {
      categorieToDelete: null,
      initLoading: true,
      customizable: 1,
      selectedlinkButton: null,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      selectedMenu: 'contacts',
      typeOfCategorieToDelte: null,
      ModalCategorieDelete: false
    }
  },

  methods: {
    ...mapActions([
      'deleteCategoriesCategorie',
      'resetErrorCategorie',
      'updateCategoriesCategorie',
      'updateOrderCategorieProject',
      'updateOrderCategorieContacts',
      'fetchCategoriesConteacts',
      'fetchCategoriesProjects',
      'fetchProjetsTypes'
    ]),
    sendConfigCategoryContact(categorie) {
      this.$router.push('/setting/categorie/contacts/' + categorie.id)
    },
    sendConfigCategoryProjet(categorie) {
      this.$router.push('/setting/categorie/projets/' + categorie.id)
    },
    closeDialog(ref) {
      this[ref] = false
      this.resetModal()
    },
    resetModal() {
      this.categorieToDelete = null
      this.resetErrorCategorie()
    },
    handleDeleteClick(categorieDelete, type) {
      this.categorieToDelete = { ...categorieDelete }
      this.typeOfCategorieToDelte = type
      this.ModalCategorieDelete = true
    },
    async handleModalDelete() {
      const response = await this.deleteCategoriesCategorie({
        categorieToDelete: this.categorieToDelete,
        type: this.typeOfCategorieToDelte
      })
      if (response) {
        this.closeDialog('ModalCategorieDelete')
      }
    },
    onColumnDropContact(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getCategoriesContacts,
        dropResult
      )
      this.updateOrderCategorieContacts(categorieOrder)

      this.updateCategoriesCategorie({
        categorie: this.getCategoriesContacts[addedIndex],
        data: {
          order: addedIndex
        },
        type: 'contacts'
      })
    },
    onColumnDropProjects(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getCategoriesProjects,
        dropResult
      )
      this.updateOrderCategorieProject(categorieOrder)
      this.updateCategoriesCategorie({
        categorie: this.getCategoriesProjects[addedIndex],
        data: {
          order: addedIndex
        },
        type: 'projects'
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    updateNameCategorie(e, categorie) {
      this.updateCategoriesCategorie({
        categorie: categorie,
        data: { name: e }
      })
    },
    updateTypeCategorie(e, categorie) {
      this.updateCategoriesCategorie({
        categorie: categorie,
        data: {
          dtypes: categorie.dtypes.map(item => {
            return item.id
          })
        }
      })
    }
  },
  components: {
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getCategoriesCategorie',
      'getCategorieLoading',
      'getCategorieError',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getCategoriesContacts',
      'getCategoriesProjects',
      'getProjectsTypes',
      'getProjectsTypesLoading'
    ])
  },
  watch: {
    $route(route) {
      this.initLoading = true
      if (route && route.name == 'categorie-contacts') {
        this.selectedMenu = 'contacts'
      } else {
        this.selectedMenu = 'projets'
      }
      setTimeout(() => {
        this.initLoading = false
      }, 500)
    }
  },
  async mounted() {
    if (this.$route && this.$route.name == 'categorie-contacts') {
      this.selectedMenu = 'contacts'
    } else {
      this.selectedMenu = 'projets'
    }
    await this.fetchProjetsTypes({
      exclude: ['fields']
    })
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.categorie-setting {
  // STYLE BLOCK DRAG AND DROP
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ss-column-drag-handle {
    top: 5px;
    left: 3px;
  }

  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    // margin-left: 25px;
    width: 100%;
    .body-drag {
      display: -webkit-box;
      justify-content: space-between;
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 15px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 139px;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .card-column-header {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    position: relative;
    overflow: visible;
    margin-top: 13px;
    border: 2px solid #5c2dd3;
    padding: 4px;
    background-color: rgba(214, 204, 242, 0.34);
    width: 100%;
    border-radius: 4px;
    border-left: 14px solid #5c2dd3;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 7px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 12px;
    width: 77px;
  }
}
</style>
<style lang="scss">
.categorie-setting {
  .input-title-sous-type {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
  .card-scene {
    max-height: calc(100vh - 261px) !important;
    height: calc(100vh - 261px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .scroll {
    max-height: calc(100vh - 300px) !important;
    height: calc(100vh - 300px) !important;
  }
}
</style>
